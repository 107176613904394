<template>
  <div class="Result">
    <div class="Result_h">
      <div class="Result_box">
        <van-image fit="cover" :src="require('@/assets/img/reply.png')" />
        <h4>
          兑换成功 <br />
          <!-- 兑换码X3342CD -->
        </h4>
        <div class="Result_content">
          <p>您的奖励已兑换，兑换记录可在“个人中心”-“成长值使用记录”查看</p>
          <p>如未查询到奖品请联系我们</p>
          <p>
            客服电话 <a class="blue" href="tel:400-100-6621">400-100-6621</a>
          </p>
        </div>
        <div class="bottom_but">
          <van-button round type="info" @click="goMine"
            >前往个人中心</van-button
          >
        </div>
        <!-- <div class="Result_content">
        <div>兑换编号：293927472812</div>
        <van-divider>使用规则</van-divider>
        <p>
          1、兑换码仅限在第三方中智薪税<a
            class="a-style"
            @click="linkDownload('http://www.ciicfc.com')"
          >
            www.ciicfc.com</a
          >使用
        </p>
        <p>
          2、兑换码仅限在第三方中智薪税<a
            class="a-style"
            @click="linkDownload('http://www.ciicfc.com')"
          >
            www.ciicfc.com</a
          >使用
        </p>

        <p>3、最终解释权归中智薪税所有 客服电话</p>
      </div>
      <div class="bottom_but">
        <van-button round type="info" @click="copy"
          >复制兑换码并前往</van-button
        >
      </div> -->
      </div>
    </div>

    <copyrightIp />
  </div>
</template>
<script>
import { Icon, Image as VanImage, Divider, Button, Toast } from "vant";
import { handleClipboard } from "@/utils/clipboard";
import copyrightIp from "@/components/copyrightIp";
export default {
  props: {
    finishOrWait: {
      default: false,
    },
  },
  components: {
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Toast.name]: Toast,
    copyrightIp,
  },
  data() {
    return {
      express: "X3342CD",
    };
  },
  methods: {
    goMine() {
      this.$router.push("/peopleCenter");
    },
    copy() {
      handleClipboard(
        this.express,
        event,
        () => {
          Toast.success("兑换码已经复制在剪贴版");
        },
        () => {
          Toast.fail("兑换码复制失败！");
        }
      );
    },
    linkDownload(url) {
      window.open(url, "_blank"); // 新窗口打开外链接
    },
  },
};
</script>
<style lang="scss" scoped>
.Result {
  width: 100%;
  height: 100%;
  background: #fff;
  .Result_h {
    min-height: calc(100% - 44px);
  }
  .Result_box {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    ::v-deep .van-image {
      width: 150px;
    }

    h4 {
      font-size: 25px;
      padding: 8px 0;
    }
    .Result_content {
      font-size: 12px;
      text-align: center;
      padding: 10px;
      // color: #afacac;
      .blue {
        color: blue;
      }
      p {
        padding-bottom: 10px;
      }
      .a-style {
        color: #0500ee;
        cursor: pointer;
        // text-decoration: underline;
      }
    }
    .bottom_but {
      width: 100%;
      padding: 10px;
    }
  }
}
</style>
