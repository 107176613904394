import Vue from "vue";
import Clipboard from "clipboard";

function clipboardSuccess() {
  Vue.prototype.$message({
    message: "复制成功",
    type: "success",
    duration: 1500,
  });
}

function clipboardError() {
  Vue.prototype.$message({
    message: "复制失败",
    type: "error",
  });
}

export function handleClipboard(text, event, onSuccess, onError) {
  event = event || {};
  const clipboard = new Clipboard(event.target, {
    text: () => text,
  });
  clipboard.on("success", () => {
    onSuccess ? onSuccess() : clipboardSuccess();
    clipboard.off("error");
    clipboard.off("success");
    clipboard.destroy();
  });
  clipboard.on("error", () => {
    onError ? onError() : clipboardError();
    clipboard.off("error");
    clipboard.off("success");
    clipboard.destroy();
  });
  clipboard.onClick(event);
}
